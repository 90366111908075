import React, { useState, useEffect, useRef } from 'react';
import { collection, addDoc, Timestamp, getDoc, doc, count, updateDoc } from 'firebase/firestore';
import { Scene, Persona } from '@soulmachines/smwebsdk';
import { db } from '../firebase';
import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';

function Emily({
  setConnectRef,
  isChatOpen,
  user,
  inputText,
  setInputText,
  info,
  conversations,
  setConversations,
  textCurrentRef,
  saveAndDisconnectRef,
  isMicOn,
  setIsMicOn,
  scene,
  setScene
}) {
  const videoRef = useRef(null);
  const [sessionId, setSessionId] = useState(null);
  const [aiChat, setAiChat] = useState('');

  const [firstMessage, setFirstMessage] = useState('');
  const countdown1Ref = useRef(null);
  const countdown2Ref = useRef(null);

  const location = useLocation();
  const path = location.pathname.split('/').pop();
  const page = path === 'conversation' ? 'conversation' : 'mockInterview';
  const timerRef = useRef(null);

  const updateTranscript = (source, text) => {
    if (source === 'persona') {
      setAiChat(text);
    }
    setConversations((prevTranscript) => [
      ...prevTranscript,
      { source, text, createdAt: Date.now() },
    ]);
  };

  const saveAndDisconnect = async () => {
    if (timerRef.current) {
      // console.log('countdown1:', countdown1Ref.current, 'countdown2:', countdown2Ref.current);
      try {
        const userRef = doc(db, 'users', user.uid); // Assuming user.uid is available
        // Update the user's todaysTimeLeft and weekTimeLeft in the database
        await updateDoc(userRef, {
          todaysTimeLeft: countdown1Ref.current,
          weekTimeLeft: countdown2Ref.current,
        });

        // Clear the timer after updating the database
        clearInterval(timerRef.current);
        timerRef.current = null;

      } catch (error) {
        console.error('Error updating user time data:', error);
      }
    } else {
      console.log('Timer not cleared');
    }
    if (conversations.length === 0) {
      setConversations([]);
      setSessionId(null);
    } else if (sessionId) {
      try {
        const data = {
          sessionId: sessionId,
          createdAt: Timestamp.now(),
          userId: user.uid,
          transcript: conversations,
          type: page || 'conversation',
        };

        await addDoc(collection(db, 'conversation'), data);
      } catch (error) {
        console.error('Error saving transcript:', error);
      }
      setConversations([]);
      setSessionId(null);
      setAiChat('');
    }

    if (scene) {
      scene.disconnect();
      setScene(null);
    }

    if (videoRef.current) {
      videoRef.current.srcObject = null;
      videoRef.current.style.display = 'none';
    }
    setIsMicOn(false);
    setInputText('');
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userRef = doc(db, 'users', user.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          // countdown1Ref.current = userSnap.data().todaysTimeLeft || 0;
          // countdown2Ref.current = userSnap.data().weekTimeLeft || 0;
          countdown1Ref.current = 300;
          countdown2Ref.current = 900;
        }
        // console.log('User data fetched:', countdown1Ref.current, countdown2Ref.current);
      } catch (error) {
        console.error('Error getting user data:', error);
      }
    };

    if (countdown1Ref.current === null && countdown2Ref.current === null) {
      // console.log('Fetching user data: ', ` countdown1: ${countdown1Ref.current}`, ` countdown2: ${countdown2Ref.current}`);
      fetchUserData();
    }

    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = ''; // Required for older browsers
    };

    const handleUnload = () => {
      saveAndDisconnect();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('unload', handleUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('unload', handleUnload);
    };
  }, [user]);

  const handleMessage = () => {
    if (firstMessage && sessionId) {
      const persona = new Persona(scene, 1);
      persona.conversationSend(firstMessage, {}, {});
      updateTranscript('user', firstMessage);
      setFirstMessage('');
      return;
    }
    if (!inputText.trim()) {
      return; // Ensure inputText is not empty
    }
    const persona = new Persona(scene, 1);
    persona.conversationSend(inputText, {}, {});
    updateTranscript('user', inputText);
    setInputText(''); // Clear input after sending
  };

  useEffect(() => {
    if (firstMessage) {
      const timeoutId = setTimeout(() => {
        handleMessage();
      }, 1000);

      return () => clearTimeout(timeoutId);
    }
  }, [sessionId])

  const connect = async () => {
    if (!videoRef.current || scene) {
      return; // Exit if there's no video element or if the scene is already connected
    }

    setConversations([]);

    videoRef.current.style.display = 'block';

    const newScene = new Scene({
      apiKey: process.env.REACT_APP_SOUL_MACHINE_API,
      videoElement: videoRef.current,
      requestedMediaDevices: { microphone: false },
      requiredMediaDevices: { microphone: false },
    });

    setScene(newScene);
    if (page !== 'conversation') {
      setFirstMessage(info);
    }
    try {
      const sessionId = await newScene.connect();
      setSessionId(sessionId);
      await newScene.startVideo();

      // Adding event listeners for state changes and recognition results
      newScene.onStateEvent.addListener((scene, event) => {
        const personaState = event.persona?.['1'];
        if (personaState?.speechState === 'speaking') {
          const personaSpeech = personaState?.currentSpeech;
          updateTranscript('persona', personaSpeech);
        }
      });

      newScene.onRecognizeResultsEvent.addListener(
        (scene, status, errorMessage, results) => {
          const result = results[0];
          if (result.final === true) {
            const userSpeech = result.alternatives[0].transcript;
            updateTranscript('user', userSpeech);
          }
        }
      );

      // timerRef.current = setInterval(() => {
      //   if (countdown1Ref.current !== null && countdown2Ref.current !== null) {
      //     // countdown1Ref.current--;
      //     // countdown2Ref.current--;
      //     // console.log(countdown1Ref.current, countdown2Ref.current);

      //     if (countdown1Ref.current <= 0 || countdown2Ref.current <= 0) {
      //       clearInterval(timerRef.current);
      //       timerRef.current = null;
      //       saveAndDisconnect();
      //     }
      //   }
      // }, 1000);


    } catch (error) {
      console.error('Connection failed:', error);
    }
  };


  useEffect(() => {
    // Set the connect reference for external usage
    setConnectRef(connect);
    textCurrentRef(handleMessage);
    saveAndDisconnectRef(saveAndDisconnect);
  }, [setConnectRef, textCurrentRef, saveAndDisconnectRef]);


  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxHeight: '770px',
        maxWidth: '992px',
        width: '100%',
        height: '90%',
        backgroundImage: `url(Su_Main.png)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          overflow: 'hidden',
          zIndex: '2000',
          width: '100%',
          height: '100%',
        }}
      >
        <video
          ref={videoRef}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            display: 'none', // Initially hidden, displayed when connected
          }}
          autoPlay
        />

        {/* Translucent Box */}
        <Box
          sx={{
            position: 'absolute',
            bottom: '40px',
            left: 0,
            right: 0,
            height: '15%', // Adjust the height as needed
            backgroundColor: 'rgba(255, 255, 255, 0.6)', // Translucent background
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '10px',
            width: '60%',
            marginLeft: '20%',
            borderRadius: '16px',
          }}
        >
          {/* Content inside the translucent box */}
          <div>
            {aiChat}
          </div>
        </Box>
      </Box>
    </Box>
  );
}

export default Emily;
